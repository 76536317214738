import { lazy, Suspense, useState} from 'react';
import './CyberSensation.css'
import circle from '../../img/circle.png';

const Spline = lazy(() => import('@splinetool/react-spline'));

const CyberSecsation = () => {
    const [isSplineLoading, setIsSplineLoading] = useState(true);

    const handleSplineLoad = () => {
        setIsSplineLoading(false); // Меняем состояние, чтобы скрыть индикатор загрузки
    }

    return(
        <div id='cyber' className='cyber'>
            <div className='cyber__container'>
                <div className='cyber__overlay-container'>
                    {/* <video className='cyber__video'
                        autoPlay={true}
                        loop={true}
                        muted
                        controls={false}
                        playsInline
                        >
                        <source src = {magicBall} type = 'video/mp4' />
                    </video>
                    <div className='cyber__overlay'></div> */}
                
                {isSplineLoading && (<img className='services__circle-decor' alt='circle' src={circle} loading="lazy"/>)}
                
                {<Suspense fallback={<p className="loading-indicator">Загрузка 3D-модели...</p>}>
                    <Spline scene="https://prod.spline.design/YT66QEjcOWnxLHCN/scene.splinecode" onLoad={handleSplineLoad}/>
                </Suspense>    }
                    
                </div>
                <div className='cyber__about'>
                <p className='cyber__title text-big'>КИБЕР СЕНСАЦИЯ</p>
                    <p className='cyber__subtitle text-medium'>попробуй сделать кастомный проект за пару минут</p>
                    <button className='cyber__button' onClick={() => {
                        window.open('https://t.me/MagnumCyber_bot');
                    }}>ПОПРОБОВАТЬ</button>
                </div>
            </div>
        </div>
    )
}

export {CyberSecsation}