import './Recomend.css'

import { useNavigate } from 'react-router-dom';

import area1_img from '../../img/portfolio/pred_portfolio.webp'
import area2_img from '../../img/portfolio/tomsk.webp'
import area3_img from '../../img/portfolio/alphabank.webp'
import area4_img from '../../img/portfolio/iri.webp'
import area5_img from '../../img/portfolio/MTC.webp'
import area6_img from '../../img/portfolio/zolotye_hity.webp'
import area7_img from '../../img/portfolio/urban_forum.webp'



import area1_img_2 from '../../img/gamzatov_portfolio.webp'
import area2_img_2 from '../../img/100_creative.webp'
import area3_img_2 from '../../img/basis_portfolio.webp'
import area4_img_2 from '../../img/pyaterochka_portfolio.webp'
import area5_img_2 from '../../img/den_znaniy_portfolio.webp'
import area6_img_2 from '../../img/supercity-portfolio.webp'
import area7_img_2 from '../../img/ostrovok_portfolio.webp'


const imageURLs = {
    'area1': [area1_img,  'ДЕНЬ ПРЕДПРИНИМАТЕЛЯ', 'Правительство Москвы.'], 
    'area2': [area2_img,  'ROBLOX BRAIN STORM', '#game #modeling #university'],
    'area3': [area3_img,  'ALFA DIGITAL', 'Онлайн конференция Alfa Bank'],
    'area4': [area4_img,  'ART PERFOMANCE ИРИ', '#3D #motion #concert'],
    'area5': [area5_img,  'MTC CYBERSATION', '#online #conf #CGI'],
    'area6': [area6_img,  'ЗОЛОТЫЕ ХИТЫ МУЗ-ТВ', '#stage #motion #avatar'],
    'area7': [area7_img,  'AR MUF', '#forum #modeling #Moscow']
  }
  
  const imageURLs2 = {
    'area8': [area1_img_2,  '100 ЛЕТ. РАССУЛ ГАМЗАТОВ', '#концерт #контент'], 
    'area9': [area2_img_2,  'MOST CREATIVE AWARDS', '#conf #awards #content'],
    'area10': [area3_img_2, 'BASIS DEVOPS CONF', '#expo #content #AR #gaming #bot'],
    'area11': [area4_img_2, 'ПЯТЕРОЧКА ROBLOX', '#metaverse #game design #modelling'],
    'area12': [area5_img_2, 'ДЕНЬ ЗНАНИЙ В КРЕМЛЕ', '#концерт #эфир #телеканал Россия'],
    'area13': [area6_img_2, 'SUPER CITY ROBLOX', '#metaverse #game design #modeling #3D'],
    'area14': [area7_img_2, 'OSTROVOK&HI-LOAD', '#expo #content  #gaming #App']
  }


  const getRandomCases = (numCases, arrData) => {
    const casesArray = Object.entries(arrData);
    const selectedCases = [];
    while (selectedCases.length < numCases && casesArray.length > 0) {
        const randomIndex = Math.floor(Math.random() * casesArray.length);
        selectedCases.push(casesArray.splice(randomIndex, 1)[0]);
    }
    return selectedCases;
};

function Recomend() {
    const navigate = useNavigate();
    const randomCasesLeftBlock = getRandomCases(1, imageURLs);
    const randomCasesRightBlock = getRandomCases(1, imageURLs2);
    const handleClick = (area) => {

        navigate(`/portfolio/${area}`);
      }
    
    return (
        <div id='portfolio' className='recomend'>
            <p className='recomend__title text-big'>ПОСЛЕДНИЕ КЕЙСЫ</p>
                <div className='recomend__container'>
                {randomCasesLeftBlock.map(([area, imageUrl]) => { 
                return(
                    
                        <div className='recomend__item recomend__first' style={{ backgroundImage: `url(${imageUrl[0]})` }} onClick={() =>
                            handleClick(area)}>
                        <div className='recomend__overlay'>
                            <p className='recoment__text' >{imageUrl[1]}. {imageUrl[2]} </p>        
                        </div>
                        </div>
                    )   
                })}
                {randomCasesRightBlock.map(([area, imageUrl]) => { 
                return(
                    
                        <div className='recomend__item recomend__first' style={{ backgroundImage: `url(${imageUrl[0]})` }} onClick={() =>
                            handleClick(area)}>
                        <div className='recomend__overlay'>
                            <p className='recoment__text' >{imageUrl[1]}. {imageUrl[2]} </p>        
                        </div>
                        </div>
                    )   
                })}
                </div>
            
                 
                
        </div>  
        );      
}

export default Recomend;