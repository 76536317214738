import './Main.css'
import magnumTitle from '../../img/magnum-logo.png'
import reel from '../../videos/reeel92mb.mp4'
import reelWebm from '../../videos/showreel_v1.webm'
import thumbnail from '../../img/reel-thumbnail-min.jpg'


function Main() {
    {console.log('Main');}
    return (
        
        <main id='main' className='main'>
            <div className='main__container'>
                <div>
                    <img loading="lazy" className='main__title-img' src={magnumTitle} alt='magnum digital studio'/>
                    <p className='main__subtitle text-medium'>ЭКСПЕРТНАЯ КОМПАНИЯ ПО СОЗДАНИЮ ВИДЕО И КОМПЬЮТЕРНОЙ ГРАФИКИ, АНИМАЦИИ, ВИРТУАЛЬНЫХ МИРОВ И ИННОВАЦИОННЫХ ТЕХНОЛОГИЙ</p>
                </div>
                {/* <div className='main__grid'>
                    <div className='main__grid-item main__grid-item1'></div>
                    <div className='main__grid-item main__grid-item2'></div>
                    <div className='main__grid-item main__grid-item3'></div>
                    <div className='main__grid-item main__grid-item4'></div>
                </div> */}
                <div className='main__overlay-container'>
                    <video
                        preload='auto'
                        className='main__reel'
                        autoPlay={false}
                        loop={true}
                        muted
                        controls={true}
                        playsInline
                        poster={thumbnail}
                    >
                        <source src={reelWebm} type='video/webm'></source>
                        <source src={reel} type='video/mp4'></source>
                        Your browser does not support the video tag.
                    </video>
                    {/* <div className='main__overlay'>
                    </div> */}
                </div>
            </div>
        </main>
    );
}

export default Main;