import React, { useState, lazy, Suspense} from 'react';
import './VideoBlock.css';

const Spline = lazy(() => import('@splinetool/react-spline'));

const ImageWithVideoOnHover = ({ imageUrl, videoUrl, title, subtitle }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className='interactiveVideo'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered ? (
        <>
          {!videoUrl.includes('.webm')  ? (
            <img
              className='interactiveVideo__image'
              src={videoUrl}
              loading='lazy'
              alt="Hover to play video"
            />
          ) : (
            <video
              className='interactiveVideo__image'
              preload="auto"
              muted
              autoPlay
              loop
              playsInline>
              <source src={videoUrl} type='video/webm'></source>
              {/*<source src={videoUrl_mp4} type='video/mp4'></source>*/}
            </video>
          )}
          <div className='interactiveVideo__overlay'>
            <div className='interactiveVideo__subtitle'>
              <p className='interactiveVideo__project-name'>{title}</p>
              <p className='interactiveVideo__project-hash'>{subtitle}</p>
            </div>
          </div>
        </>
      ) : (
        <>
          <img
            className='interactiveVideo__image'
            src={imageUrl}
            loading='lazy'
            alt="Hover to play video"
          />
          <div className='interactiveVideo__overlay'>
            <div className='interactiveVideo__subtitle'>
              <p className='interactiveVideo__project-name'>{title}</p>
              <p className='interactiveVideo__project-hash'>{subtitle}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ImageWithVideoOnHover;
