import { useState } from 'react';
import { Link } from 'react-router-dom';
import './Portfolio.css';
import { useNavigate } from 'react-router-dom';
import ImageWithVideoOnHover from '../VideoBlock/VideoBlock';
import area1_img from '../../img/portfolio/pred_portfolio.webp'
import area2_img from '../../img/portfolio/tomsk.webp'
import area3_img from '../../img/portfolio/alphabank.webp'
import area4_img from '../../img/portfolio/iri.webp'
import area5_img from '../../img/portfolio/MTC.webp'
import area6_img from '../../img/portfolio/zolotye_hity.webp'
import area7_img from '../../img/portfolio/urban_forum.webp'

import videoUrl1 from '../../img/den_predprinemately.webm' 
import videoUrl2 from '../../img/tomsk_1.webm'
import videoUrl3 from '../../img/alphaban_vertical.webm'
import videoUrl4 from '../../img/iri-big.webm'
import videoUrl5 from '../../img/MTS shot 7.webm'
import videoUrl6 from '../../img/zolotye hity_small.webm'
import videoUrl7 from '../../img/ar_vertical.webm'

// import mainImgTomsk from '../../videos/tomsk.mp4'
// import aboutImgTomsk from '../../img/projects/tomsk/img4.png'
// import img1 from '../../img/roblox_tomsk2.gif'
// import img2 from '../../img/projects/tomsk/img2.png'
// import img3 from '../../img/projects/tomsk/img1.png'
// import img4 from '../../img/roblox_tomsk.gif'

// import mainIriImg from '../../img/projects/ИРИ/image 13.png'

// // видео для портфолио называются как картинка но на самом деле это ВИДЕО
// import mainAlpha  from '../../videos/alifa.mp4'
// import mainIri  from '../../videos/iri_20s.mp4'
// import mainMts  from '../../videos/mts 20 sec.mp4'
// import mainHity  from '../../videos/zolotye hity 20s.mp4'
// import mainUrban  from '../../videos/AR20 sec.mp4'
// import mainPred  from '../../videos/den predprinemately.mp4'

// import mainGamzatov from '../../videos/big-videos-portfolio/гамзатов.mp4'
// import mainCreative100 from '../../videos/big-videos-portfolio/100креативных.mp4'
// import mainZnanyi from '../../videos/big-videos-portfolio/деньзнаний.mp4'
// import mainPyaterochka from '../../videos/big-videos-portfolio/пятерочка.mp4'
// import mainBasis from '../../videos/big-videos-portfolio/базис.mp4'
// import mainOstrovok from '../../videos/big-videos-portfolio/осторовок.mp4'
// import mainSuperCity from '../../videos/big-videos-portfolio/супер сити.mp4'

// // картинки под проект
// import aboutPred from '../../img/portfolio-about-small/predprinimatel.png'
// import aboutAlpha from '../../img/portfolio-about-small/alpha.png'
// import aboutIri from '../../img/portfolio-about-small/iri.png'
// import aboutMts from '../../img/portfolio-about-small/mts.png'
// import aboutHity from '../../img/portfolio-about-small/muz.png'
// import aboutUrban from '../../img/portfolio-about-small/urban.png'

// import aboutGamzatov from '../../img/portfolio-about-small/gamzatov.png'
// import aboutCreative100 from '../../img/portfolio-about-small/100-creative.png'
// import aboutBasis from '../../img/portfolio-about-small/basis.png'
// import aboutPyaterochka from '../../img/portfolio-about-small/pyaterochka.png'
// import aboutDenZnaniy from '../../img/portfolio-about-small/den-znaniy.png'
// import aboutSuperCity from '../../img/portfolio-about-small/super-city.png'
// import aboutOstrovok from '../../img/portfolio-about-small/ostrovok.png'

// // раскадровка проекта
// import alphaFrame1 from '../../img/alphaFrame1.png'
// import alphaFrame2 from '../../img/alphaFrame2.png'
// import alphaFrame3 from '../../img/alphaFrame3.png'
// import alphaFrame4 from '../../img/alphaFrame4.png'

// import predFrame1 from '../../img/0408.jpg'
// import predFrame2 from '../../img/ezgif.com-video-to-gif (7).gif'
// import predFrame3 from '../../img/ezgif.com-video-to-gif (4).gif'
// import predFrame4 from '../../img/pred_portfolio.jpg'

// import urbanFrame1 from '../../img/urbanFrame1.JPG'
// import urbanFrame2 from '../../img/urbanFrame2.gif'
// import urbanFrame3 from '../../img/urbanFrame3.png'
// import urbanFrame4 from '../../img/urbanFrame4.png'

// import hityFrame1 from '../../img/frameHity1.png'
// import hityFrame2 from '../../img/frameHity2.png'
// import hityFrame3 from '../../img/frameHity3.png'
// import hityFrame4 from '../../img/frameHity4.png'

// import iriFrame1 from '../../img/iriFrame1.png'
// import iriFrame2 from '../../img/iriFrame2.png'
// import iriFrame3 from '../../img/iriFrame3.png'
// import iriFrame4 from '../../img/iriFrame4.png'

// import mtsFrame from '../../img/mtsFrame.png'

// // section 2 portfolio


import area1_img_2 from '../../img/gamzatov_portfolio.webp'
import area2_img_2 from '../../img/100_creative.webp'
import area3_img_2 from '../../img/basis_portfolio.webp'
import area4_img_2 from '../../img/pyaterochka_portfolio.webp'
import area5_img_2 from '../../img/den_znaniy_portfolio.webp'
import area6_img_2 from '../../img/supercity-portfolio.webp'
import area7_img_2 from '../../img/ostrovok_portfolio.webp'

// import gamzatovFrame1 from '../../img/gif/взрыв гамзатов.gif'
// import gamzatovFrame2 from '../../img/gif/дым гамзатов.gif'
// import gamzatovFrame3 from '../../img/gif/ковер гамзатов.gif'
// import gamzatovFrame4 from '../../img/gif/луп гамзатов.gif'

// import creative100Frame1 from '../../img/gif/100 креативных логолуп розовый.gif'
// import creative100Frame2 from '../../img/gif/100 креативных логолуп черный.gif'
// import creative100Frame3 from '../../img/gif/100 креативных.gif'
// import creative100Frame4 from '../../img/gif/100 креатинвых лидеров.gif'

// import pyaterochkaFrame1 from '../../img/gif/бегунок 1.gif'
// import pyaterochkaFrame2 from '../../img/gif/бегунок 2.gif'
// import pyaterochkaFrame3 from '../../img/gif/бегунок 3.gif'
// import pyaterochkaFrame4 from '../../img/gif/бегунок 4.gif'

// import znanyiFrame1 from '../../img/gif/балерина.gif'
// import znanyiFrame2 from '../../img/gif/лебеди.gif'
// import znanyiFrame3 from '../../img/gif/неродевочка.gif'
// import znanyiFrame4 from '../../img/gif/часы.gif'

// import basisFrame1 from '../../img/portfolio/basis/Basis__dev_oops_конференция_стенд_2024_13.jpg'
// import basisFrame2 from '../../img/portfolio/basis/Basis__dev_oops_конференция_стенд_2024_14.jpg'
// import basisFrame3 from '../../img/portfolio/basis/интерфейс ар.jpg'
// import basisFrame4 from '../../img/portfolio/basis/стенд базис экран.jpg'

// import ostrovokFrame1 from '../../img/portfolio/ostrovok/Островок_конференция_стенд_2023_5.jpg'
// import ostrovokFrame2 from '../../img/portfolio/ostrovok/Ресурс 11e.png'
// import ostrovokFrame3 from '../../img/portfolio/ostrovok/Ресурс 2bot_ostrovok.png'
// import ostrovokFrame4 from '../../img/portfolio/basis/Basis__dev_oops_конференция_стенд_2024_14.jpg'

// import superCityFrame1 from '../../img/portfolio/supercity/HighresScreenshot00014.png'
// import superCityFrame2 from '../../img/portfolio/supercity/HighresScreenshot00004.png'
// import superCityFrame3 from '../../img/portfolio/supercity/Фасад магазина 2.jpg'
// import superCityFrame4 from '../../img/portfolio/supercity/Снимок экрана 2022-08-10 165036.png'

const imageURLs = {
  'area1': [area1_img, videoUrl1, 'ДЕНЬ ПРЕДПРИНИМАТЕЛЯ', '#concert #opening #motion'], 
  'area2': [area2_img, videoUrl2, 'ROBLOX BRAIN STORM', '#game #modeling #university'],
  'area3': [area3_img, videoUrl3, 'ALFA DIGITAL', '#conf #motion #3D'],
  'area4': [area4_img, videoUrl4, 'ART PERFOMANCE ИРИ', '#3D #motion #concert'],
  'area5': [area5_img, videoUrl5, 'MTC CYBERSATION', '#online #conf #CGI'],
  'area6': [area6_img, videoUrl6, 'ЗОЛОТЫЕ ХИТЫ МУЗ-ТВ', '#stage #motion #avatar'],
  'area7': [area7_img, videoUrl7, 'AR MUF', '#forum #modeling #Moscow']
}

const imageURLs2 = {
  'area8': [area1_img_2, area1_img_2, '100 ЛЕТ. РАССУЛ ГАМЗАТОВ', '#концерт #контент'], 
  'area9': [area2_img_2, area2_img_2, 'MOST CREATIVE AWARDS', '#conf #awards #content'],
  'area10': [area3_img_2, area3_img_2, 'BASIS DEVOPS CONF', '#expo #content #AR #gaming #bot'],
  'area11': [area4_img_2, area4_img_2, 'ПЯТЕРОЧКА ROBLOX', '#metaverse #game design #modelling'],
  'area12': [area5_img_2, area5_img_2, 'ДЕНЬ ЗНАНИЙ В КРЕМЛЕ', '#концерт #эфир #телеканал Россия'],
  'area13': [area6_img_2, area6_img_2, 'SUPER CITY ROBLOX', '#metaverse #game design #modeling #3D'],
  'area14': [area7_img_2, area7_img_2, 'OSTROVOK&HI-LOAD', '#expo #content  #gaming #App']
}

function Portfolio() {
  const navigate = useNavigate();
  

  const handleClick = (area) => {
    // const imageUrl = imageURLs[area];
    // const props = { ...imageUrl[4] };
    
    // Object.keys(props).forEach(key => {
    //   if (typeof props[key] === 'string' && !props[key].startsWith('http')) {
    //     props[key] = process.env.PUBLIC_URL + props[key];
    //   } else if (Array.isArray(props[key])) {
    //     props[key] = props[key].map(img => img.startsWith('http') ? img : process.env.PUBLIC_URL + img);
    //   }
    // });
    
    // localStorage.setItem('clickedImageProps', JSON.stringify(props));
    navigate(`/portfolio/${area}`);
  }
  
  const handleClick2 = (area) => {
    // const imageUrl = imageURLs2[area];
    // const props = { ...imageUrl[4] };
    
    // Object.keys(props).forEach(key => {
    //   if (typeof props[key] === 'string' && !props[key].startsWith('http')) {
    //     props[key] = process.env.PUBLIC_URL + props[key];
    //   } else if (Array.isArray(props[key])) {
    //     props[key] = props[key].map(img => img.startsWith('http') ? img : process.env.PUBLIC_URL + img);
    //   }
    // });
    
    // localStorage.setItem('clickedImageProps', JSON.stringify(props));
    navigate(`/portfolio/${area}`);
  }
  
  return (
    <div className="portfolio">
      <div className="container">
        {Object.entries(imageURLs).map(([area, imageUrl]) => {
          return(<div  key={area} className={`portfolio__item ${area} area`} onClick={() => handleClick(area)}>
            <ImageWithVideoOnHover imageUrl={imageUrl[0]} videoUrl={imageUrl[1]} title={imageUrl[2]} subtitle={imageUrl[3]} />
          </div>)
        }
      )}
      </div>
      <div className="container2">
        {Object.entries(imageURLs2).map(([area, imageUrl]) => {
          return(<div  key={area} className={`portfolio__item ${area} area`} onClick={() => handleClick2(area)}>
            <ImageWithVideoOnHover imageUrl={imageUrl[0]} videoUrl={imageUrl[1]} title={imageUrl[2]} subtitle={imageUrl[3]} />
          </div>)
        }
      )}
      </div>
    </div>
  );
}

export default Portfolio;

// const alphaTitle = 'ALFA Digital. Онлайн конференция Alfa Bank'
// const alphaSubtitle = 'Видели одежду эфира от которой все ахнули? Скорее всего нет, мероприятие было внутренним, для сотрудников компании. Им рассказали о топовых продуктах банка.А мы показали это и доказали, с помощью множества графики, видеороликов и инфографики. Заставки, титры, оформление выступлений спикеров, интерактивная графика, презентационные ролики'

// const mtsTitle = 'Cyber Sation. Конференция по кибербозапосности от MTC'
// const mtsSubtitle = 'Сотни умных тезисов от спикеров МТС заполнили площадку «Кибердом». Контент, разработанный нами, заполнил все экраны.  Интро ролики, видеовизитки спикеров, инфографика, тематические блоки и расписание - теперь команде художников есть о чем рассказать внукам. '

// const tomskTitle = 'Brainstorm. Локация в метавселенной Roblox для Paraweb'
// const tomskSubtitle = 'В Томске столько университетов, что им уже тесно. Мы поместили топовые университеты города в безграничную метавселенную. Теперь любой абитуриент может познакомиться с их историей, фишками, пообщаться с ректором. Заодно набрать баллы и обменять их на призы'

// const urbanTitle = 'Moscow Urban Forum. Дополненная реальность в парке «Зарядье»'
// const urbanSubtitle = 'Любой посетитель парка через свой телефон увидел гигантские 3В объекты. Показ мод на парящем подиуме, digital спектакль с историей дружбы между роботом и девочкой, интерактивный AR в небе. Пока птицы думали, что их все фотографируют, люди любовались дополненной реальностью над парком.'

// const iriTitle = 'ВФМ 2024. Проект ИРИ «Digital Берёзы»'
// const iriSubtitle = 'Медиаинсталляция в Олимпийском парке Сочи «Сириус».Разработка дизайна, застройка, техническое сопровождение от Brothers Production. 5 тематических дней, 3 концерта, более 100 позиций созданного  контента. Все это под присмотром тысяч студентов из разных стран на Всемирном Фестивале Молодежи.'

// const hityTitle = '«Золотые хиты». Муз-тв'
// const hitySubtitle = 'Концерт с ТВ трансляцией. Сделали контент на сценические экраны, сделали эфирную графику, даже ведущего сделали. Не живого, а 3D аватара, который повторял движения и мимику реального диктора.'

// const predTitle = 'День Предпринимателя. Правительство Москвы.'
// const predSubtitle = 'Ежегодное мероприятие стало уникальным. Благодаря контенту. Части сценических экранов включались только для отображения элементов «выходящих» за пределы контента. Создавалась иллюзия дополнительного объема и ощущение, что элементы парят в воздухе.'

// const gamzatovTitle = '100 ЛЕТ. РАССУЛ ГАМЗАТОВ'
// const gamzatovSubtitle = 'Серия концертов в честь юбилея великого поэта Рассула Гамзатова. Терабайты архивов обрели новую жизнь, став частью контента для 50 номеров.'

// const creative100Title = 'MOST CREATIVE AWARDS'
// const creative100Subtitle = 'Чтобы награждение самых креативных персон прошло ярче, мы залили всю площадку контентом. Интро ролики, визитки спикеров, профайлы победителей и оформление всей площадки в Moscow City.'

// const basisTitle = 'BASIS DEVOPS CONF'
// const basisSubtitle = 'Превратили стенд в игровую площадку. На экране нужно найти метки, в чат-боте ответить на вопросы, собрать бонусы в AR и сразиться в финальной битве за игровым столом.'

// const pyaterochkaTitle = 'ПЯТЕРОЧКА ROBLOX'
// const pyaterochkaSubtitle = 'Метавселенская активация на День защиты детей. Дети сотрудников компании встретились в виртуальном мире. Изучали в игре профессии родителей, соревновались и веселились.'

// const znanyiTitle = 'ДЕНЬ ЗНАНИЙ В КРЕМЛЕ'
// const znanyiSubtitle = 'Торжественный концерт в честь 1 сентября с трансляцией на федеральном канале из ГКД. Контент для выступления десятков артистов создавался нашими профессионалами'

// const superCityTitle = 'SUPER CITY ROBLOX'
// const superCitySubtitle = 'Игра в Roblox собрала более 2 млн игроков. Интегрировали партнеров в локацию: «Мегафон» и «Московский транспорт». Приблизили будущее. Вот оно - залетайте.'

// const ostrovolTitle = 'OSTROVOK & HighLoad++'
// const ostrovokSubtitle = 'Сделали контент на напольный и центральный экран, украсили это полноценным квестом в чат боте. Получили сотни лидов и тотальное вовлечение.'

// const blankTitle = 'Название проекта'
// const blantSubtitle = 'Абзац текста о проекте. Немного подробной информации для человека, который зашел на сайт magum.Немного подробной информации для человека, который зашел на сайт magum.'