import Collapsible from '../Collapsible/Collapsible';
import ContactForm from '../ContactForm/ContactForm';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import UpButton from '../UpButton/UpButton';
import Steps from '../Steps/Steps';
import circle from '../../img/circle.png'
import './ServicesExpand.scss';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Test from '../Test/Text';
import Spline from '@splinetool/react-spline';

import add_mobile_img1 from '../../img/services/Реклама/Sozdanie_grafiki.png'
import add_mobile_img2 from '../../img/services/Реклама/Tizeri.png'
import add_mobile_img3 from '../../img/services/Реклама/SCREEN_COMP_new0211.png'
import add_mobile_img4 from '../../img/services/Реклама/koncept.png'

import motion_mobile_img1 from '../../img/services/Моушен/efir_graph.png'
import motion_mobile_img2 from '../../img/services/Моушен/media_holm.png'
import motion_mobile_img3 from '../../img/services/Моушен/Content.png'
import motion_mobile_img4 from '../../img/services/Моушен/001215.png'

import avatars_mobile_img1 from '../../img/services/Аватары/digital_veducshii.jpg'
import avatars_mobile_img2 from '../../img/services/Аватары/mascot_ lisenok.png'
import avatars_mobile_img3 from '../../img/services/Аватары/roblox_skin.png'
import avatars_mobile_img4 from '../../img/services/Аватары/influencer.png'

import metaverse_mobile_img1 from '../../img/services/Метаверс/roblox_city.png'
import metaverse_mobile_img2 from '../../img/services/Метаверс/Снимок экрана 2022-08-10 165022.png'
import metaverse_mobile_img3 from '../../img/services/Метаверс/Фасад магазина 2.jpg'
import metaverse_mobile_img4 from '../../img/services/Метаверс/RobloxScreenShot_раннер_магазин_робот.png'

import xr_mobile_img1 from '../../img/services/AR_XR_VR/WEB GL.jpg'
import xr_mobile_img2 from '../../img/services/AR_XR_VR/XR.jpg'
import xr_mobile_img3 from '../../img/services/AR_XR_VR/WEB GL.jpg'
import xr_mobile_img4 from '../../img/services/AR_XR_VR/traking.png'

import ai_mobile_img1 from '../../img/services/Нейросети/Чат-боты-и-ИИ.png'
import ai_mobile_img2 from '../../img/services/Нейросети/ии-видео.png'
import ai_mobile_img3 from '../../img/services/Нейросети/AI ПО.png'
import ai_mobile_img4 from '../../img/services/Нейросети/Анимации через ИИ.jpg'

import UslugiButton from '../UslugiButton/UslugiButton'
import {Helmet} from "react-helmet";
const image_blocks = [
  'https://prod.spline.design/S-XrDA4k2XQ3iTTs/scene.splinecode',
  'SplineModels/motion_plashka.splinecode',
  'SplineModels/avatar_plashka.splinecode',
  'SplineModels/metaverse_plashka.splinecode',
  'SplineModels/arvr_plashka.splinecode',
  'SplineModels/ai_plashka.splinecode'
]

const image_blocks_mobile = [
  [add_mobile_img1, add_mobile_img2, add_mobile_img3, add_mobile_img4],
  [motion_mobile_img1, motion_mobile_img2, motion_mobile_img3, motion_mobile_img4],
  [avatars_mobile_img1, avatars_mobile_img2, avatars_mobile_img3, avatars_mobile_img4],
  [metaverse_mobile_img1, metaverse_mobile_img2, metaverse_mobile_img3, metaverse_mobile_img4],
  [xr_mobile_img1, xr_mobile_img2, xr_mobile_img3, xr_mobile_img4],
  [ai_mobile_img1, ai_mobile_img2, ai_mobile_img3, ai_mobile_img4],

]

const services_title = [
  'РЕКЛАМА',
  'MOTION',
  'AVATAR',
  'METAVERSE',
  'AR/VR/XR',
  'НЕЙРОСЕТИ'
]


            

export default function ServicesExpand() {
  const path = useLocation();
  const [location, setLocation] = useState('');
  const [servicesList, setServicesList] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const locationPath = path.pathname.split('/')[1];
    setLocation(locationPath);

    switch (locationPath) {
      case 'add':
        setServicesList(services_params[1]);
        break;
      case 'motion':
        setServicesList(services_params[2]);
        break;
      case 'avatars':
        setServicesList(services_params[3]);
        break;
      case 'metaverse':
        setServicesList(services_params[4]);
        break;
      case 'ar-xr-vr':
        setServicesList(services_params[5]);
        break;
      case 'ai':
        setServicesList(services_params[6]);
        break;
      default:
        console.log('default');
    }
  }, [path.pathname]);
  
    return (
      <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{servicesList ? servicesList[6]:''}</title>
        <meta name="description" content={servicesList ? servicesList[7]:''}/>
        <link rel="canonical" href={`https://magnum.design${path.pathname}`} />
      </Helmet>
        <div id="bg-wrap" style={{position: 'fixed', width: '100%', height: '100%', zIndex: -1}}>
          <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice">
          <defs>
          <radialGradient id="Gradient1" cx="50%" cy="50%" fx="10%" fy="50%" r=".5"><animate attributeName="fx" dur="40s" values="0%;50%;0%" repeatCount="indefinite"></animate><stop offset="0%" stop-color="rgba(160, 255, 0, 1)"></stop><stop offset="100%" stop-color="rgba(160, 255, 0, 0)"></stop></radialGradient>
          <radialGradient id="Gradient3" cx="50%" cy="50%" fx="10%" fy="50%" r=".5"><animate attributeName="fx" dur="40s" values="0%;50%;0%" repeatCount="indefinite"></animate><stop offset="0%" stop-color="rgba(160, 255, 0, 1)"></stop><stop offset="100%" stop-color="rgba(160, 255, 0, 0)"></stop></radialGradient>
          <radialGradient id="Gradient2" cx="50%" cy="50%" fx="10%" fy="50%" r=".5"><animate attributeName="fx" dur="30.5s" values="0%;50%;0%" repeatCount="indefinite"></animate><stop offset="0%" stop-color="rgba(52, 40, 100, 1)"></stop><stop offset="100%" stop-color="rgba(52, 40, 100, 0)"></stop></radialGradient>
          </defs>
          <rect x="13.744%" y="1.18473%" width="30%" height="30%" fill="url(#Gradient1)" transform="rotate(0 50 50)"><animate attributeName="x" dur="100s" values="25%;0%;25%" repeatCount="indefinite"></animate><animate attributeName="y" dur="100s" values="0%;25%;0%" repeatCount="indefinite"></animate><animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="100s" repeatCount="indefinite"></animateTransform></rect>
          <rect x="-2.17916%" y="35.4267%" width="100%" height="100%" fill="url(#Gradient2)" transform="rotate(0 50 50)"><animate attributeName="x" dur="150s" values="-25%;0%;-25%" repeatCount="indefinite"></animate><animate attributeName="y" dur="150s" values="0%;50%;0%" repeatCount="indefinite"></animate><animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="150s" repeatCount="indefinite"></animateTransform>
          </rect>
        </svg>
        </div>
        <div className="services-expand">
          <Header/>
          <div className='services-expand__mobile-only'>
            {servicesList ? servicesList[4] : ''}
          </div>
              <div className="content mobile-only">
                <div className="list-group list-group-flush">
                  <Collapsible title={servicesList ? servicesList[0][0] : ''} pos={1}>
                    <div className='content__card'>
                      <p className='content__subtitle'>{servicesList ? servicesList[1][0] : ''}</p>
                      <img
                        className='contemt__image'
                        src={servicesList ? servicesList[5][0] : ''}
                      ></img>
                    </div>
                  </Collapsible>
                  <Collapsible title={servicesList ? servicesList[0][1] : ''}>
                  <div className='content__card'>
                      <p className='content__subtitle'>{servicesList ? servicesList[1][1] : ''}</p>
                      <img
                        className='contemt__image'
                        src={servicesList ? servicesList[5][1] : ''}
                      ></img>
                    </div>
                  </Collapsible>
                  <Collapsible title={servicesList ? servicesList[0][2] : ''}>
                  <div className='content__card'>
                      <p className='content__subtitle'>{servicesList ? servicesList[1][2] : ''}</p>
                      <img
                        className='contemt__image'
                        src={servicesList ? servicesList[5][2] : ''}
                      ></img>
                    </div>
                  </Collapsible>
                  <Collapsible title={servicesList ? servicesList[0][3] : ''}>
                  <div className='content__card'>
                      <p className='content__subtitle'>{servicesList ? servicesList[1][3] : ''}</p>
                      <img
                        className='contemt__image'
                        src={servicesList ? servicesList[5][3] : ''}
                      ></img>
                    </div>
                  </Collapsible>
                </div>
              </div>
          <div className="desktop-only">
            <Spline scene={servicesList ? servicesList[3] : ''} />
          </div>
          <div className="services-exand__servises-list">
            <Spline scene={servicesList ? servicesList[2] : ''} />
          </div>
          <UslugiButton/>
          <img className='service-expand__circle' src={circle}/>
          <Steps/>
          <ContactForm/>
          <Footer/>
        </div>
      </>
      );
}

const add = [
  "Создание графики",
  "Тизеры и трейлеры",
  "VFX контент для соцсетей",
  "Разработка концепции"
];

const add_texts = [
  'Cоздание графики.Производство Full CG видео. Ролики полностью созданы на базе 3D графики. Все стадии производства. От концепта и раскадровок, до финального продукта. Outdoor реклама, ролики для TV, 3D naked eye, контент для web.',
  'Тизеры и трейлеры. Поддержка рекламного продвижения видео материалами. Монтаж исходных видео и усиление графикой. Разработка и адаптация графического пакета. Продвижение игр, телепрограмм, кино.',
  'VFX контент для соцсетей. Коммерческий контент для соцсетей с использованием псевдо дополненной реальности. Отрисовка вирусных видео для популяризации бренда.',
  'Разработка концепции. Предоставление заказчику раскадровок, аниматиков и презентаций. Поиск идеального воплощения, гибкая адаптация правок и пожеланий клиента.'
]
const add_title = ['Цифровая реклама и контент для брендов — Magnum Digital Studio']
const add_description = ['Создаем рекламные кампании с использованием AR/VR, motion-дизайна и CGI-графики. Привлеките аудиторию через инновации и креатив.']




const motion = [
  "Эфирная графика для ТВ",
  "Медиадизайн для инсталляций",
  "Контент для концертов и event",
  "Интерактивный контент для expo",
]

const motion_texts = [
  'Эфирная графика для ТВ. Выпускаем графические пакеты для телевизионных продуктов. Заставки, перебивки, тизеры и трейлеры, профайлы, титровальные плашки. Следуем брендбуку и предлагаем свои графические решения.',
  'Медиадизайн для инсталляций. Оригинальный подход к производству графики, основанный на последних технологиях и популярных направлениях. Сочетаем нескольких технологий: экраны, лазеры, проекторы, сенсорные панели.',
  'Контент для концертов и event. Концерты, шоу, конференции и премии. Создание графики для номеров артистов. Основные заставки, объявления номинантов и спикеров для премий и конференций.',
  'Интерактивный контент для expo. Интерактивный контент с использованием трекеров и считыванием положения человека или декора. Графика взаимодействует с посетителем стенда. Контролируем техническую составляющую и предоставляем контент.'
]

const motion_title = ['Motion-дизайн для шоу-бизнеса и брендов — Magnum Digital Studio']

const motion_description = ['Захватывающая анимация и креативный motion-дизайн для рекламы, видеопрезентаций и соцсетей. Движение, которое привлекает внимание.']

const avatars = [
  "Digital ведущие",
  "Маскоты",
  "Игровые персонажи",
  "Виртуальные инфлюенсеры",
]

const avatar_texts = [
  'Считываем движения человека и передаем графическому персонажу в режиме реального времени или через записанное видео. Ведущие обзоров, прогнозов погоды, виртуальные артисты. Отрисовка любых образов, техническое сопровождение.',
  'Отрисовка и анимация мультяшных персонажей для брендов. Производим видео и фото материалы с персонажем для рекламы, соцсетей и СМИ.',
  'Создаем диджитал двойников реальных людей, оживляем известных исторических деятелей. Помещаем их в игру, на интерактивные выставочные стенды или в дополненную реальность.',
  'Оптимизировали процесс производства графики с виртуальным аватаром в главной роли. Долгий процесс подготовки дает результат в виде скоростного производства контента с персонажем в дальнейшем.'
]

const avatars_title = ['Виртуальные аватары ведущих — Magnum Digital Studio']

const avatars_description = ['Разработка реалистичных цифровых аватаров для презентаций, вебинаров и виртуальных событий. Высокая детализация и адаптивность.']

const metaverse = [
  "Roblox",
  "webGL",
  "Decentraland",
  "Spatial",
]

const metaverse_texts = [
'Используем игровые платформы для любых желаний брендов. Decentraland -  для любителей крипты, Spatial – для онлайн конференций. Roblox для всего другого и большего. Выпусти много популярных локаций. ',
'Собственные метавселенные и 3D сайты. Легкий доступ без регистрации. Интегрируем интерактивные объекты на классические сайты.',
'Создаем рекламные материалы, контролируем посевы и работу с блогерами. Официальное продвижение на платформе и создание UGC элементов бренда.',
'',
]

const metaverse_title = ['Создание метавселенных на заказ — Magnum Digital Studio']

const metaverse_description = ['Захватывающая анимация и креативный motion-дизайн для рекламы, видеопрезентаций и соцсетей. Движение, которое привлекает внимание.']

const ar_xr_vr = [
  "Разработка приложений и web",
  "Интерактивный AR и VR",
  "Контент для XR студий",
  "Разработка ПО для трекинга",
]

const ar_xr_vr_texts = [
'Приложение или web решение для работы интерактивных панелей. Эффективно вовлекаем пользователей через технологичные решения. Визуализация процессов и продуктов компании. Сочетание интерактива и контента.',
'Дополненная и виртуальная реальность под нашим управлением находят новые сферы применения. Объединяем технологии и интегрируем их в новые задачи. Навигация в AR, коллаборации с digital художниками, геймификация маркетинговых активаций, AR квесты.',
'Производство контента для студий с применением трекинга и элементов дополненной реальности. Съемки уникальных клипов и контент для телеканалов. Настраиваем и контролируем всю техническую составляющую от экранов и освещения до камер и трекинка, производим и интегрируем специальный контент',
'Настраиваем камеры для считывания движений человека и передаем анимацию виртуальным аватарам. Занимаемся отладкой под конкретные задачи. Сочетаем несколько технологий.',
]

const ar_xr_vr_title = ['AR/VR/XR разработки для бизнеса и развлечений — Magnum Digital Studio']

const ar_xr_vr_description = ['Погружаем в дополненную и виртуальную реальность. AR/VR/XR-решения для обучения, рекламы и геймификации.']

const ai = [
  "Чат-боты на основе ИИ",
  "Генерация ИИ видео",
  "ПО с нейронными сетями",
  "создание анимаций через ИИ",
]


const ai_texts = [
  'Для легкого доступа к нашим разработкам используем боты или лендинги. Через QR код приглашаем в браузер или чат-бот, где удобно можно пользоваться разработанными нами фичами.',
  'Мастерски управляем самыми актуальными нейронками. Компьютерное "видение" классических картин, великих стихотворений и исторических событий. Инсталляции для музеев, арт галерей, уникальные сценические номера для артистов.',
  'Видео, фото, интеграция AI в метавселенные и чатботы. ИИ селфи и тематические арты в тематике события. В несколько нажатий кнопок на стенде или в телефоне, посетитель получает готовое фото для удовольствия и соцсетей.',
  'Совершенствуем ПО для считывания движений человека и записи анимации. Анимация лица, анимация тела без специальных костюмов.',
]

const ai_title = ['Разработка контента и чат-ботов на основе нейросетей - Magnum Digital Studio']

const ai_description = ['Генерим крутой визуал, цепляющий контент и умных чат-ботов с помощью нейросетей. Технологии, которые делают ваш проект заметным и эффектным']

const spline_links = [
'https://prod.spline.design/UYriUkqPlv6dY9YC/scene.splinecode',
'https://prod.spline.design/xyQnzpK7QwuC7xw5/scene.splinecode',
'https://prod.spline.design/3goqaKtaFjnnLeBE/scene.splinecode',
'https://prod.spline.design/rg5P9ol6N0KicZw2/scene.splinecode',
'https://prod.spline.design/Zcoh-Pi2l9R1uEkV/scene.splinecode',
'https://prod.spline.design/Q56YECjWTHvQC4BO/scene.splinecode',
]



const services_params = {
  1: [add, add_texts, image_blocks[0], spline_links[0], services_title[0], image_blocks_mobile[0], add_title, add_description],
  2: [motion, motion_texts, image_blocks[1], spline_links[1], services_title[1], image_blocks_mobile[1], motion_title, motion_description],
  3: [avatars, avatar_texts, image_blocks[2], spline_links[2], services_title[2], image_blocks_mobile[2], avatars_title, avatars_description],
  4: [metaverse, metaverse_texts, image_blocks[3], spline_links[3], services_title[3], image_blocks_mobile[3], metaverse_title, metaverse_description],
  5: [ar_xr_vr, ar_xr_vr_texts, image_blocks[4], spline_links[4], services_title[4], image_blocks_mobile[4], ar_xr_vr_title, ar_xr_vr_description],
  6: [ai, ai_texts, image_blocks[5], spline_links[5], services_title[5], image_blocks_mobile[5], ai_title, ai_description], 
}

