import Footer from "./blocks/Footer/Footer";

function NotFound(){
    return (
      
      <div className="not_found_main">
        <div className="not_found">
  
          <h1 className="project__text project__about-title">Ой, что-то пошло не&nbsp;так!</h1>
          
          <p className="project__text project__about-text">К сожалению, страница, которую вы ищете, потерялась в бескрайних просторах интернета.
          Возможно, она решила отправиться в отпуск или просто сбежала от нас.</p> 
          <p className="project__text project__about-text">Что делать?</p>
          <ul>
            <li className="project__text project__about-text">Проверьте адрес на опечатки.</li>
            <li className="project__text project__about-text">Вернитесь на <a href="/">главную страницу</a> и начните с&nbsp;нового.</li>
            <li className="project__text project__about-text">Или просто посмотрите на наши <a href="/portfolio">последние проекты</a>, которые точно не пропадут!</li>
          </ul>
       

          <Footer/>
          </div>
      </div>  
      
      );
    }
    
export default NotFound;