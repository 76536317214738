import "./Project.css"
import { useEffect, useState} from "react";
import Header from '../Header/Header';
import Footer from '../Footer/Footer'
import video1 from '../../videos/ar master.mp4'
import { useLocation } from 'react-router-dom';
import Recomend from '../Recomend/Recomend'
import MTSPage from "../MTSPage/MTSPage";
import IRIPage from "../IRIPage/IRIPage";
import AlphaPage from "../AlphaPage/AlphaPage";
import SuperCity from "../SuperCity/SuperCity";
import ContactForm from "../ContactForm/ContactForm";
import Spline from '@splinetool/react-spline';
import preloadImage from '../../img/preloadImage.jpg'



import mainImgTomsk from '../../videos/tomsk_1.mp4'
import aboutImgTomsk from '../../img/projects/tomsk/img4.png'
import img1 from '../../img/roblox_tomsk2.gif'
import img2 from '../../img/projects/tomsk/img2.png'
import img3 from '../../img/projects/tomsk/img1.png'
import img4 from '../../img/roblox_tomsk.gif'

import mainIriImg from '../../img/projects/ИРИ/image 13.png'

// видео для портфолио называются как картинка но на самом деле это ВИДЕО
import mainAlpha  from '../../videos/alifa.mp4'
import mainIri  from '../../videos/iri_20s.mp4'
import mainMts  from '../../videos/mts 20 sec.mp4'
import mainHity  from '../../videos/zolotye hity 20s_1.mp4'
import mainUrban  from '../../videos/AR20 sec.mp4'
import mainPred  from '../../videos/den predprinemately.mp4'

import mainGamzatov from '../../videos/big-videos-portfolio/гамзатов.mp4'
import mainCreative100 from '../../videos/big-videos-portfolio/100креативных.mp4'
import mainZnanyi from '../../videos/big-videos-portfolio/деньзнаний.mp4'
import mainPyaterochka from '../../videos/big-videos-portfolio/пятерочка.mp4'
import mainBasis from '../../videos/big-videos-portfolio/базис.mp4'
import mainOstrovok from '../../videos/big-videos-portfolio/осторовок.mp4'
import mainSuperCity from '../../videos/big-videos-portfolio/супер сити.mp4'

// картинки под проект
import aboutPred from '../../img/portfolio-about-small/predprinimatel.png'
import aboutAlpha from '../../img/portfolio-about-small/alpha.png'
import aboutIri from '../../img/portfolio-about-small/iri.png'
import aboutMts from '../../img/portfolio-about-small/mts.png'
import aboutHity from '../../img/portfolio-about-small/muz.png'
import aboutUrban from '../../img/portfolio-about-small/urban.png'

import aboutGamzatov from '../../img/portfolio-about-small/gamzatov.png'
import aboutCreative100 from '../../img/portfolio-about-small/100-creative.png'
import aboutBasis from '../../img/portfolio-about-small/basis.png'
import aboutPyaterochka from '../../img/portfolio-about-small/pyaterochka.png'
import aboutDenZnaniy from '../../img/portfolio-about-small/den-znaniy.png'
import aboutSuperCity from '../../img/portfolio-about-small/super-city.png'
import aboutOstrovok from '../../img/portfolio-about-small/ostrovok.png'

// раскадровка проекта
import alphaFrame1 from '../../img/alphaFrame1.png'
import alphaFrame2 from '../../img/alphaFrame2.webp'
import alphaFrame3 from '../../img/alphaFrame3.webp'
import alphaFrame4 from '../../img/alphaFrame4.webp'

import predFrame1 from '../../img/0408.webp'
import predFrame2 from '../../img/ezgif.com-video-to-gif (7).gif'
import predFrame3 from '../../img/ezgif.com-video-to-gif (4).gif'
import predFrame4 from '../../img/pred_portfolio.jpg'

import urbanFrame1 from '../../img/urbanFrame1.webp'
import urbanFrame2 from '../../img/urbanFrame2.gif'
import urbanFrame3 from '../../img/urbanFrame3.webp'
import urbanFrame4 from '../../img/urbanFrame4.webp'

import hityFrame1 from '../../img/frameHity1.webp'
import hityFrame2 from '../../img/frameHity2.webp'
import hityFrame3 from '../../img/frameHity3.webp'
import hityFrame4 from '../../img/frameHity4.webp'

import iriFrame1 from '../../img/iriFrame1.webp'
import iriFrame2 from '../../img/iriFrame2.webp'
import iriFrame3 from '../../img/iriFrame3.webp'
import iriFrame4 from '../../img/iriFrame4.webp'

import mtsFrame from '../../img/mtsFrame.webp'

// section 2 portfolio

import gamzatovFrame1 from '../../img/gif/взрыв гамзатов.gif'
import gamzatovFrame2 from '../../img/gif/дым гамзатов.gif'
import gamzatovFrame3 from '../../img/gif/ковер гамзатов.gif'
import gamzatovFrame4 from '../../img/gif/луп гамзатов.gif'

import creative100Frame1 from '../../img/gif/100 креативных логолуп розовый.gif'
import creative100Frame2 from '../../img/gif/100 креативных логолуп черный.gif'
import creative100Frame3 from '../../img/gif/100 креативных.gif'
import creative100Frame4 from '../../img/gif/100 креатинвых лидеров.gif'

import pyaterochkaFrame1 from '../../img/gif/бегунок 1.gif'
import pyaterochkaFrame2 from '../../img/gif/бегунок 2.gif'
import pyaterochkaFrame3 from '../../img/gif/бегунок 3.gif'
import pyaterochkaFrame4 from '../../img/gif/бегунок 4.gif'

import znanyiFrame1 from '../../img/gif/балерина.gif'
import znanyiFrame2 from '../../img/gif/лебеди.gif'
import znanyiFrame3 from '../../img/gif/неродевочка.gif'
import znanyiFrame4 from '../../img/gif/часы.gif'

import basisFrame1 from '../../img/portfolio/basis/Basis__dev_oops_конференция_стенд_2024_13.jpg'
import basisFrame2 from '../../img/portfolio/basis/Basis__dev_oops_конференция_стенд_2024_14.jpg'
import basisFrame3 from '../../img/portfolio/basis/интерфейс ар.jpg'
import basisFrame4 from '../../img/portfolio/basis/стенд базис экран.jpg'

import ostrovokFrame1 from '../../img/portfolio/ostrovok/Островок_конференция_стенд_2023_5.jpg'
import ostrovokFrame2 from '../../img/portfolio/ostrovok/Ресурс 11e.png'
import ostrovokFrame3 from '../../img/portfolio/ostrovok/Ресурс 2bot_ostrovok.png'
import ostrovokFrame4 from '../../img/portfolio/basis/Basis__dev_oops_конференция_стенд_2024_14.jpg'

import superCityFrame1 from '../../img/portfolio/supercity/HighresScreenshot00014.png'
import superCityFrame2 from '../../img/portfolio/supercity/HighresScreenshot00004.png'
import superCityFrame3 from '../../img/portfolio/supercity/Фасад магазина 2.jpg'
import superCityFrame4 from '../../img/portfolio/supercity/Снимок экрана 2022-08-10 165036.png'
import {Helmet} from "react-helmet";



function Project() {
    const path = useLocation();
    const [location, setLocation] = useState('');
    const [portfolioList, setPortfolioList] = useState(null);
  
    useEffect(() => {
      window.scrollTo(0, 0);
      const locationPath = path.pathname.split('/')[2];
      setLocation(locationPath);

      let area;
      switch (locationPath) {
        case 'area1':
          area = 'area1';
          break;
        case 'area2':
          area = 'area2';
          break;
        case 'area3':
          area = 'area3';
          break;
        case 'area4':
          area = 'area4';
          break;
        case 'area5':
          area = 'area5';
          break;
        case 'area6':
          area = 'area6';
          break;
        case 'area7':
          area = 'area7';
          break;
        case 'area8':
          area = 'area8';
          break;
        case 'area9':
          area = 'area9';
          break;
        case 'area10':
          area = 'area10';
          break;
        case 'area11':
          area = 'area11';
          break;
        case 'area12':
          area = 'area12';
          break;
        case 'area13':
          area = 'area13';
          break;
        case 'area14':
          area = 'area14';
          break;
        
        default:
          area = null;
      }
  
      if (area) {
        setPortfolioList(imageURLs[area][0]);
      }
      console.log(locationPath)
    }, [path.pathname]);

    return(
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{portfolioList?.head_title}</title>
          <meta name="description" content={portfolioList?.description} />
          <link rel="canonical" href={`https://magnum.design${path.pathname}`} />
        </Helmet>
        <div id="bg-wrap" style={{position: 'fixed', width: '100%', height: '100%', zIndex: -1}}>
        <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice">
        <defs>
        <radialGradient id="Gradient1" cx="50%" cy="50%" fx="10%" fy="50%" r=".5"><animate attributeName="fx" dur="40s" values="0%;50%;0%" repeatCount="indefinite"></animate><stop offset="0%" stop-color="rgba(160, 255, 0, 1)"></stop><stop offset="100%" stop-color="rgba(160, 255, 0, 0)"></stop></radialGradient>
        <radialGradient id="Gradient3" cx="50%" cy="50%" fx="10%" fy="50%" r=".5"><animate attributeName="fx" dur="40s" values="0%;50%;0%" repeatCount="indefinite"></animate><stop offset="0%" stop-color="rgba(160, 255, 0, 1)"></stop><stop offset="100%" stop-color="rgba(160, 255, 0, 0)"></stop></radialGradient>
        <radialGradient id="Gradient2" cx="50%" cy="50%" fx="10%" fy="50%" r=".5"><animate attributeName="fx" dur="30.5s" values="0%;50%;0%" repeatCount="indefinite"></animate><stop offset="0%" stop-color="rgba(52, 40, 100, 1)"></stop><stop offset="100%" stop-color="rgba(52, 40, 100, 0)"></stop></radialGradient>
        </defs>
        <rect x="13.744%" y="1.18473%" width="30%" height="30%" fill="url(#Gradient1)" transform="rotate(0 50 50)"><animate attributeName="x" dur="100s" values="25%;0%;25%" repeatCount="indefinite"></animate><animate attributeName="y" dur="100s" values="0%;25%;0%" repeatCount="indefinite"></animate><animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="100s" repeatCount="indefinite"></animateTransform></rect>
        <rect x="-2.17916%" y="35.4267%" width="100%" height="100%" fill="url(#Gradient2)" transform="rotate(0 50 50)"><animate attributeName="x" dur="150s" values="-25%;0%;-25%" repeatCount="indefinite"></animate><animate attributeName="y" dur="150s" values="0%;50%;0%" repeatCount="indefinite"></animate><animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="150s" repeatCount="indefinite"></animateTransform>
        </rect>
        </svg>
        </div>
        <Header/>
        {
            location === 'area5' ? <MTSPage/> :
            location === 'area4' ? <IRIPage/> :
            location === 'area3' ? <AlphaPage/> :
            location === 'area13' ? <SuperCity/> :
            <main className="project">
                <div className='project__overlay-container'>
                    {
                        location === 'area10'  ? 
                        <div className="project__spline">
                            <Spline scene="https://prod.spline.design/UDuCiMzHrT6GIBTq/scene.splinecode" />
                        </div> :
                        location === 'area14'  ? 
                        <div className="project__spline">
                            <Spline scene="https://prod.spline.design/h-BuDKhiVKZ6Z5bO/scene.splinecode" />
                        </div> :
                        <>
                            <video
                                preload='auto'
                                className='project__reel'
                                src={`${portfolioList?.mainImg}#t=5`}
                                autoPlay={true}
                                loop={true}
                                muted
                                controls={false}
                                playsInline
                                poster={preloadImage}
                            >
                            Your browser does not support the video tag.
                            </video>
                            <div className='project__overlay'>
                            </div>
                    </>

                    }

                </div>
                <div className="section project__about">
                    <div className="project__about-container">
                        <h1 className="project__text project__about-title">{portfolioList?.title.substring(0)}</h1>
                        <p className="project__text project__about-text">{portfolioList?.subtitle.substring(0)}</p>
                    </div>
                    <img className="project__about-image" src={portfolioList?.aboutImg} loading="lazy"/>
                </div>
                {
                    portfolioList?.gallery.length < 4 ?
                    <div className="project__image-container">
                        <img className="project__image project__image-1" src={portfolioList?.gallery[0]} style={{width: '100%', height: 'auto'}} loading="lazy"></img>
                    </div> :
                    <div className="project__image-container">
                        <img className={`project__image project__image-1}`} src={portfolioList?.gallery[0]} loading="lazy"></img>
                        <img className="project__image project__image-2" src={portfolioList?.gallery[1]} loading="lazy"></img>
                        <img className="project__image project__image-3" src={portfolioList?.gallery[2]} loading="lazy"></img>
                        <img className="project__image project__image-4" src={portfolioList?.gallery[3]} loading="lazy"></img>
                    </div>
                }
            </main>
        }
        <Recomend/>
        <ContactForm/>
        <Footer/>
        </>
    )
}

export default Project


const alphaTitle = 'ALFA Digital. Онлайн конференция Alfa Bank';
const alphaSubtitle = 'Видели одежду эфира от которой все ахнули? Скорее всего нет, мероприятие было внутренним, для сотрудников компании. Им рассказали о топовых продуктах банка.А мы показали это и доказали, с помощью множества графики, видеороликов и инфографики. Заставки, титры, оформление выступлений спикеров, интерактивная графика, презентационные ролики';
const alpha_title = 'Виртуальная движуха для сотрудников Альфа-Банка';
const alpha_description = 'Космическое оформление внутреннего ивента для Альфа-Банка: графика, интро-ролики, титры и инфографика, которые прокачали презентацию топовых продуктов банка.';

const mtsTitle = 'Cyber Station. Конференция по кибербезопасности от МТС';
const mtsSubtitle = 'Сотни умных тезисов от спикеров МТС заполнили площадку «Кибердом». Контент, разработанный нами, заполнил все экраны.  Интро ролики, видеовизитки спикеров, инфографика, тематические блоки и расписание - теперь команде художников есть о чем рассказать внукам.';
const mts_title = 'Cyber Station — Хайповый контент для конференции МТС';
const mts_description = 'Интерактивные видеоролики, визитки спикеров, инфографика и расписание прокачали площадку «Кибердом». Погружение в мир кибербезопасности с огонь-визуалом.';

const tomskTitle = 'Brainstorm. Локация в метавселенной Roblox для Paraweb';
const tomskSubtitle = 'В Томске столько университетов, что им уже тесно. Мы поместили топовые университеты города в безграничную метавселенную. Теперь любой абитуриент может познакомиться с их историей, фишками, пообщаться с ректором. Заодно набрать баллы и обменять их на призы.';
const tomsk_title = 'Brainstorm — Roblox-локация для томских вузов';
const tomsk_description = 'Закинули топовые университеты Томска в метавселенную. Теперь абитуриенты гоняют по виртуалке, изучают фишки вузов и фармят призы.';

const urbanTitle = 'Moscow Urban Forum. Дополненная реальность в парке «Зарядье»';
const urbanSubtitle = 'Любой посетитель парка через свой телефон увидел гигантские 3В объекты. Показ мод на парящем подиуме, digital спектакль с историей дружбы между роботом и девочкой, интерактивный AR в небе. Пока птицы думали, что их все фотографируют, люди любовались дополненной реальностью над парком.';
const urban_title = 'Moscow Urban Forum — Дополненная реальность в центре движухи';
const urban_description = 'Парящий подиум, digital-спектакль и гигантские 3D-объекты. Гости парка «Зарядье» зависли в интерактивной реальности.';

const iriTitle = 'ВФМ 2024. Проект ИРИ «Digital Берёзы»';
const iriSubtitle = 'Медиаинсталляция в Олимпийском парке Сочи «Сириус». Разработка дизайна, застройка, техническое сопровождение от Brothers Production. 5 тематических дней, 3 концерта, более 100 позиций созданного контента. Все это под присмотром тысяч студентов из разных стран на Всемирном Фестивале Молодежи.';
const iri_title = 'Digital Берёзы — Хайповый арт для Всемирного Фестиваля Молодежи';
const iri_description = 'Медиавыступления в «Сириусе»: 5 дней, 3 концерта и тонна контента. Все это заряжало тысячи студентов со всего мира.';

const hityTitle = '«Золотые хиты». Муз-ТВ';
const hitySubtitle = 'Концерт с ТВ трансляцией. Сделали контент на сценические экраны, сделали эфирную графику, даже ведущего сделали. Не живого, а 3D аватара, который повторял движения и мимику реального диктора.';
const hity_title = '«Золотые хиты» — Концерт с прокачкой от 3D-аватара';
const hity_description = 'Эфирная графика, экранный контент и инновационный 3D-аватар ведущего, который качал вместе со зрителями.';

const predTitle = 'День Предпринимателя. Правительство Москвы.';
const predSubtitle = 'Ежегодное мероприятие стало уникальным. Благодаря контенту. Части сценических экранов включались только для отображения элементов «выходящих» за пределы контента. Создавалась иллюзия дополнительного объема и ощущение, что элементы парят в воздухе.';
const pred_title = 'День Предпринимателя — Эффектный визуал для главной сцены';
const pred_description = 'Экраны с вау-эффектами создавали иллюзию парящих элементов. Полный разрыв шаблона на главном бизнес-ивенте года.';

const gamzatovTitle = '100 ЛЕТ. РАССУЛ ГАМЗАТОВ';
const gamzatovSubtitle = 'Серия концертов в честь юбилея великого поэта Рассула Гамзатова. Терабайты архивов обрели новую жизнь, став частью контента для 50 номеров.';
const gamzatov_title = 'Рассул Гамзатов — Топовый визуал для юбилейных концертов';
const gamzatov_description = 'Архивы поэта обрели второе дыхание в визуальном контенте для 50 огненных номеров.';

const creative100Title = 'MOST Creative Awards';
const creative100Subtitle = 'Чтобы награждение самых креативных персон прошло ярче, мы залили всю площадку контентом. Интро ролики, визитки спикеров, профайлы победителей и оформление всей площадки в Moscow City.';
const creative100_title = 'MOST Creative Awards — Церемония награждения с огонь-контентом';
const creative100_description = 'Moscow City стал эпицентром креатива: интро-ролики, визитки и профайлы победителей сделали вечер максимально стильным.';

const basisTitle = 'BASIS DEVOPS CONF';
const basisSubtitle = 'Превратили стенд в игровую площадку. На экране нужно найти метки, в чат-боте ответить на вопросы, собрать бонусы в AR и сразиться в финальной битве за игровым столом.';
const basis_title = 'BASIS DevOps Conf — Стенд, который зашел на новый левел';
const basis_description = 'Игровая площадка со сбором бонусов в AR, ботами и финальной баталией. Участники рубились и прокачивали навыки.';

const pyaterochkaTitle = 'ПЯТЕРОЧКА ROBLOX';
const pyaterochkaSubtitle = 'Метавселенская активация на День защиты детей. Дети сотрудников компании встретились в виртуальном мире. Изучали в игре профессии родителей, соревновались и веселились.';
const pyaterochka_title = 'Пятерочка Roblox — Детский движ в метавселенной';
const pyaterochka_description = 'Виртуальная тусовка для детей сотрудников: изучение профессий, веселые челленджи и море фана в метавселенной.';

const znanyiTitle = 'ДЕНЬ ЗНАНИЙ В КРЕМЛЕ';
const znanyiSubtitle = 'Торжественный концерт в честь 1 сентября с трансляцией на федеральном канале из ГКД. Контент для выступления десятков артистов создавался нашими профессионалами.';
const znanyi_title = 'День знаний в Кремле — Торжественный концерт с хайповым контентом';
const znanyi_description = 'Мощный визуал для десятков артистов на концерте ко Дню знаний в ГКД.';

const superCityTitle = 'SUPER CITY ROBLOX';
const superCitySubtitle = 'Игра в Roblox собрала более 2 млн игроков. Интегрировали партнеров в локацию: «Мегафон» и «Московский транспорт». Приблизили будущее. Вот оно - залетайте.';
const superCity_title = 'Super City Roblox — Игровая локация с топовыми брендами';
const superCity_description = 'Игра с интеграцией «Мегафона» и «Московского транспорта» собрала миллионы игроков. Будущее уже здесь!';

const ostrovokTitle = 'OSTROVOK & HighLoad++';
const ostrovokSubtitle = 'Сделали контент на напольный и центральный экран, украсили это полноценным квестом в чат боте.'
const ostrovok_title = 'Ostrovok & HighLoad++ — Квестовый стенд, который зашел в массы'
const ostrovok_description = 'Напольный контент, чат-боты и вовлекающий квест. Участники ловили метки, решали задачи и фармили лиды'

const blankTitle = 'Название проекта'
const blantSubtitle = 'Абзац текста о проекте. Немного подробной информации для человека, который зашел на сайт magum.Немного подробной информации для человека, который зашел на сайт magum.'


const imageURLs = {
    'area1': [{
      mainImg: mainPred,
      aboutImg: aboutPred,
      title: predTitle,
      subtitle: predSubtitle,
      gallery: [predFrame1, predFrame2, predFrame3, predFrame4],
      head_title: pred_title,
      description: pred_description
    }],
    'area2': [{
      mainImg: mainImgTomsk,
      aboutImg: aboutImgTomsk,
      title: tomskTitle,
      subtitle: tomskSubtitle,
      gallery: [img1, img2, img3, img4],
      head_title: tomsk_title,
      description: tomsk_description
    }],
    'area3': [{
      mainImg: mainAlpha,
      aboutImg: aboutAlpha,
      title: alphaTitle,
      subtitle: alphaSubtitle,
      gallery: [alphaFrame1, alphaFrame2, alphaFrame3, alphaFrame4],
      head_title: alpha_title,
      description: alpha_description
    }],
    'area4': [{
      mainImg: mainIri,
      aboutImg: aboutIri,
      title: iriTitle,
      subtitle: iriSubtitle,
      gallery: [iriFrame1, iriFrame2, iriFrame3, iriFrame4],
      head_title: iri_title,
      description: iri_description
    }],
    'area5': [{
      mainImg: mainMts,
      aboutImg: aboutMts,
      title: mtsTitle,
      subtitle: mtsSubtitle,
      gallery: [mtsFrame],
      head_title: mts_title,
      description: mts_description
    }],
    'area6': [{
      mainImg: mainHity,
      aboutImg: aboutHity,
      title: hityTitle,
      subtitle: hitySubtitle,
      gallery: [hityFrame1, hityFrame2, hityFrame3, hityFrame4],
      head_title: hity_title,
      description: hity_description
    }],
    'area7': [{
      mainImg: mainUrban,
      aboutImg: aboutUrban,
      title: urbanTitle,
      subtitle: urbanSubtitle,
      gallery: [urbanFrame1, urbanFrame2, urbanFrame3, urbanFrame4],
      head_title: urban_title,
      description: urban_description
    }],
    'area8': [{
      mainImg: mainGamzatov,
      aboutImg: aboutGamzatov,
      title: gamzatovTitle,
      subtitle: gamzatovSubtitle,
      gallery: [gamzatovFrame1, gamzatovFrame2, gamzatovFrame3, gamzatovFrame4],
      head_title: gamzatov_title,
      description: gamzatov_description
    }],
    'area9': [{
      mainImg: mainCreative100,
      aboutImg: aboutCreative100,
      title: creative100Title,
      subtitle: creative100Subtitle,
      gallery: [creative100Frame1, creative100Frame2, creative100Frame3, creative100Frame4],
      head_title: creative100_title,
      description: creative100_description
    }],
    'area10': [{
      mainImg: mainBasis,
      aboutImg: aboutBasis,
      title: basisTitle,
      subtitle: basisSubtitle,
      gallery: [basisFrame1, basisFrame2, basisFrame3, basisFrame4],
      head_title: basis_title,
      description: basis_description
    }],
    'area11': [{
      mainImg: mainPyaterochka,
      aboutImg: aboutPyaterochka,
      title: pyaterochkaTitle,
      subtitle: pyaterochkaSubtitle,
      gallery: [pyaterochkaFrame1, pyaterochkaFrame2, pyaterochkaFrame3, pyaterochkaFrame4],
      head_title: pyaterochka_title,
      description: pyaterochka_description
    }],
    'area12': [{
      mainImg: mainZnanyi,
      aboutImg: aboutDenZnaniy,
      title: znanyiTitle,
      subtitle: znanyiSubtitle,
      gallery: [znanyiFrame1, znanyiFrame2, znanyiFrame3, znanyiFrame4],
      head_title: znanyi_title,
      description: znanyi_description
    }],
    'area13': [{
      mainImg: mainSuperCity,
      aboutImg: aboutSuperCity,
      title: superCityTitle,
      subtitle: superCitySubtitle,
      gallery: [superCityFrame1, superCityFrame2, superCityFrame3, superCityFrame4],
      head_title: superCity_title,
      description: superCity_description
    }],
    'area14': [{
      mainImg: mainOstrovok,
      aboutImg: aboutOstrovok,
      title: ostrovokTitle,
      subtitle: ostrovokSubtitle,
      gallery: [ostrovokFrame1, ostrovokFrame2, ostrovokFrame3, ostrovokFrame4],
      head_title: ostrovok_title,
      description: ostrovok_description
    }]
}
  